
::placeholder {
  opacity:0.7;
}

.searchOptions{
  position: absolute;
  top:50px;
  margin-top: 10px;
  background:white;
  border-radius:12px;
  border:1px solid #BCBCBC;
  padding:30px;
  font-family: "Comfortaa", cursive;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:90%;
  height:190px;
  overflow-y: auto;
  font-size: 0.7rem;
  color:#042459;
  z-index:400;
  background: white;
  z-index:500;
}

.searchOption{
  margin-top: 8px;
  cursor: pointer;
}


.textInput{
  background:white;
  border-radius:8px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Montserrat";
  border:1px solid #ABABAB;
  padding-top: 1.8vh;
  padding-bottom: 1.8vh;
  width:70%;
  margin-top: 5vh;
  color:#989898;
}
.textInput:focus{
  outline:none;
}

.customInputDate{
  margin-top: 0.4vh;
  margin-left: 0vw;
  padding-left: 0.8vw;
  margin-right: 0px;
  text-align: left;
  font-size:0.9rem;
  color:#787878;
  font-family: 'Nunito', sans-serif;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  border-radius:100px;
  padding-top:0.8vh;
  padding-bottom:0.8vh;
  border:0px;
  display: inline-block;
  vertical-align: middle;
}
.customInputDate:focus{
  outline: none;
}

.customInputDateSpan{
  display: inline-block;
  vertical-align: middle;
  margin-left: 0px;
  text-align: left;
}

.customInputFilterSpan{
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
  margin-right: 10px;
  text-align: left;
}

.customInputDateTitle{
  color:white;
  font-size: 0.8rem;
  font-family: 'Nunito', sans-serif;
}

.customInputDatePickerTitle{
  color:#BCBCBC;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
}

.reactDatepicker {
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  font-size: .7rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: .3rem;
  display: inline-block;
  position: relative;
}

.submitInputLarge{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:5px;
  font-family: "Montserrat";
  font-size: 0.8rem;
  width:166px;
  text-align: center;
  margin-left:20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border:0px;
  color:white;
  background:#4818b3;
  display: inline-block;
  vertical-align: bottom;
  border:1px solid white;
}

.submitInputLarge:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitInputLargePurple{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:5px;
  font-family: "Montserrat";
  font-size: 0.8rem;
  width:166px;
  text-align: center;
  margin-left:20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border:0px;
  color:white;
  background:#4818b3;
  display: inline-block;
  vertical-align: bottom;
  border:1px solid white;
}

.submitInputLargePurple:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitInput{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:5px;
  font-family: "Montserrat";
  font-size: 0.8rem;
  width:106px;
  text-align: center;
  margin-left:20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border:0px;
  color:white;
  background:#4818b3;
  display: inline-block;
  vertical-align: bottom;
  border:1px solid white;
}

.submitInput:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitInputPurple{
  border-radius:10px;
  box-shadow: 0px 8px 12px rgba(10,0,180,0.2);
  padding:5px;
  font-family: "Montserrat";
  font-size: 0.8rem;
  width:106px;
  text-align: center;
  margin-left:20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border:0px;
  color:white;
  background:#0070FF;
  display: inline-block;
  vertical-align: bottom;
  border:0px solid white;
}

.submitInputPurple:hover{
  cursor:pointer;
  opacity:0.7;
}

.submitInputIcon{
  width:12px;
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;
}

.formInputSpan{
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0px;
  margin-right: 80px;
  margin-top: 18px;
  text-align: left;
}

.formInputTitle{
  color:#ABABAB;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
}

.formInput{
  background:white;
  border-radius:8px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Montserrat";
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:90%;
  margin-top: 5px;
  border:1px solid #ABABAB;
  color:#989898;
}
.formInput:focus{
  outline:none;
}

.formInputTextarea{
  background:white;
  border-radius:8px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:27px;
  font-family: "Montserrat";
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  width:90%;
  margin-top: 5px;
  border:1px solid #ABABAB;
  min-height: 10vh;
  max-height:10vh;
  color:#989898;
}
.formInputTextarea:focus{
  outline:none;
  border:2px solid #0055DD;
}

.formInputTitleSmall{
  color:#ABABAB;
  font-size: 0.8rem;
  font-family: 'Nunito', sans-serif;
}

.formInputSmall{
  background:white;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:30px;
  font-family: "Montserrat";
  padding-top: 0.9vh;
  padding-bottom: 0.9vh;
  width:90%;
  margin-top: 4px;
  border:1px solid #ABABAB;
  color:#989898;
}
.formInputSmall:focus{
  outline:none;
  border:2px solid #0055DD;
}


.formInputCheckboxTitle{
  color:#ABABAB;
  font-size: 0.9rem;
  font-family: 'Nunito', sans-serif;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}

.formCheckboxInput{
  background:white;
  border-radius:12px;
  padding:30px;
  font-family: "Montserrat";
  display: inline-block;
  vertical-align: middle;
  border:0px;
  color:#989898;
}
.formCheckboxInput:focus{
  outline:none;
}

.dropdownInputSpan{
  display: inline-block;
  vertical-align: middle;
  margin-left: 0vw;
  margin-right: 1.8vw;
  margin-top: 20px;
  text-align: left;
}

.dropdownInput{
  background:white;
  border-radius:12px;
  box-shadow: 0px 2px 12px rgba(10,0,180,0.1);
  padding:0px;
  font-family: "Montserrat";
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  width:18.5vw;
  margin-top: 5px;
  border:0px;
  color:#989898;
}
.dropdownInput:focus{
  outline:none;
}
